<template>
	<div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
		<event-hub></event-hub>
		<side-bar :sidebarLinks="sidebarLinks">
			<mobile-menu slot="content"></mobile-menu>
		</side-bar>
		<div class="main-panel">
			<top-navbar></top-navbar>
			<div class="content">
				<dashboard-content></dashboard-content>
			</div>
		</div>
	</div>
</template>

<script>
import DashboardContent from "./Content.vue";
import TopNavbar from "./TopNavbar.vue";
import MobileMenu from "./MobileMenu.vue";

export default {
	components: {
		DashboardContent,
		TopNavbar,
		MobileMenu,
	},
	data() {
		return {
			//Title: title in the sidebar
			//Icon: icon in the sidebar, google material icons
			//Path: names the route
			//ActiveFor: custom added, an array of route names that should
			//light up the "father" element
			sidebarLinks: [
				{
					title: "data.map.title",
					icon: "map",
					path: { name: "map" },
					activeFor: [],
				},
				{
					title: "data.herd.title",
					icon: "hub",
					path: { name: "herds.index" },
					activeFor: ["assets.show"],
				},
				{
					title: "data.assetAlerts.title",
					icon: "warning",
					path: { name: "assetAlerts.index" },
					activeFor: [],
				},
			],
		};
	},
};
</script>
